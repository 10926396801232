<template>
  <div class="row">
    <!-- Camera Sidebar -->
    <b-sidebar
      id="sidebar-camera"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Cameras</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <div v-if="cams.length > 0">
          <b-card
            v-for="camera in cams"
            v-bind:key="camera._id"
            v-bind:item="camera"
            class="mt-2 ml-1 mr-1"
          >
            <b-card-title>{{ camera.name }}</b-card-title>
            <h5>Region:</h5>
            <p>{{ camera.regionName }}</p>
            <h5>Site:</h5>
            <p>{{ camera.siteName }}</p>
            <h5>URL:</h5>
            <p>{{ camera.url }}</p>
            <h5>Image URL:</h5>
            <p>{{ camera.img }}</p>
          </b-card>
        </div>
        <div v-else class="m-2 align-text-center">
          <h4>No Cameras</h4>
        </div>
      </template>
    </b-sidebar>

    <!-- RDP Sidebar -->
    <b-sidebar
      id="sidebar-rdps"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">RDPs</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <div v-if="rdps.length > 0">
          <b-card
            v-for="rdp in rdps"
            v-bind:key="rdp._id"
            v-bind:item="rdp"
            class="mt-2 ml-1 mr-1"
          >
            <b-card-title>{{ rdp.name }}</b-card-title>
            <h5>Region:</h5>
            <p>{{ rdp.regionName }}</p>
            <h5>Site:</h5>
            <p>{{ rdp.siteName }}</p>
            <h5>IP:</h5>
            <p>{{ rdp.ip }}</p>
          </b-card>
        </div>
        <div v-else class="m-2 align-text-center">
          <h4>No RDPs</h4>
        </div>
      </template>
    </b-sidebar>

    <!-- QuickLink Sidebar -->
    <b-sidebar
      id="sidebar-qlinks"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Quick Links</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <div v-if="qlinks.length > 0">
          <b-card
            v-for="qlink in qlinks"
            v-bind:key="qlink._id"
            v-bind:item="qlink"
            class="mt-2 ml-1 mr-1"
          >
            <div class="row">
              <div class="col-3">
                <b-avatar
                  size="4rem"
                  :variant="'' + qlink.class + ''"
                  :text="qlink.shortname"
                >
                </b-avatar>
              </div>
              <div class="col-9">
                <b-card-title>{{ qlink.name }}</b-card-title>
                <h5>Region:</h5>
                <p>{{ qlink.regionName }}</p>
                <h5>Site:</h5>
                <p>{{ qlink.siteName }}</p>
                <h5>URL:</h5>
                <p>{{ qlink.url }}</p>
              </div>
            </div>
          </b-card>
        </div>
        <div v-else class="m-2 align-text-center">
          <h4>No QuickLinks</h4>
        </div>
      </template>
    </b-sidebar>

    <!-- Contractor Table -->
    <div class="col-12">
      <div class="vue-form-wizard mb-3 md">
        <div class="d-flex justify-content-between flex-wrap mr-1 ml-1">
          <div class="d-flex align-items-center mb-1 mt-1">
            <span class="">Show</span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '20', '50', '100']"
              class="mx-1"
            />
            <span class="text-nowrap">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle.collapse-1
                variant="primary"
                @click="showCreationWizard()"
              >
                Add Contractor
              </b-button>
            </span>
          </div>
          <div>
            <div class="custom-search d-flex justify-content-right mt-1 mb-1">
              <div class="d-flex align-items-center">
                <span class="mr-1">Search</span>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- creation Wizard -->
        <b-collapse id="contractor-creator" class="ml-1 mr-1">
          <create-contractor-wizard></create-contractor-wizard>
        </b-collapse>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'actions'">
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="text-body align-middle mr-1 trash-icon"
                @click="showDeleteModal(props.row)"
              />
              <feather-icon
                icon="EditIcon"
                size="16"
                class="text-body align-middle mr-25 table-icon"
                @click="showEditorInSidebar(props.row)"
              />
            </span>

            <!-- Column: class -->
            <span v-else-if="props.column.field === 'shortname'">
              <a
                :class="'b-avatar badge-' + props.row.class + ' rounded-circle'"
                style="width: 35px; height: 35px"
                ><span class="b-avatar-text" style="font-size: calc(12.8px)">
                  <span>{{ props.formattedRow[props.column.field] }}</span>
                </span>
              </a>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>
            <!-- Column: Name -->
            <span v-else-if="props.column.field === 'name'">
              <div @click="handleRouteToEditor(props.row._id)" class="link">
                {{ props.row.firstName }} {{ props.row.lastName }}
              </div>
            </span>
            <!-- Column: Cameras -->
            <span v-else-if="props.column.field === 'cameras'">
              <feather-icon
                icon="EyeIcon"
                v-if="props.row.cams.length > 0"
                @click="showCamerasInSidebar(props.row.cams)"
                class="table-icon"
              />
              {{ props.row.cams.length }}
            </span>
            <!-- Column: rdps -->
            <span v-else-if="props.column.field === 'rdps'">
              <feather-icon
                icon="EyeIcon"
                v-if="props.row.rdps.length > 0"
                @click="showRDPsInSidebar(props.row.rdps)"
                class="table-icon"
              />
              {{ props.row.rdps.length }}
            </span>
            <!-- Column: Quicklinks -->
            <span v-else-if="props.column.field === 'qlinks'">
              <feather-icon
                icon="EyeIcon"
                v-if="props.row.qlinks.length > 0"
                @click="showQuickLinksInSidebar(props.row.qlinks)"
                class="table-icon"
              />
              {{ props.row.qlinks.length }}
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap mb-1 ml-1"> Show</span>

                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '20', '50', '100']"
                  class="mx-1 mb-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-1 mr-1"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>

    <!-- Deletion Modal -->
    <b-modal
      id="delete-contractor-modal"
      hide-footer
      centered
      @hidden="clearDeleteModal()"
    >
      <template #modal-title> Delete Contractor</template>
      <div class="d-block text-center">
        <h3>
          Are you sure you want to delete: {{ contractorToDelete.firstName }}
          {{ contractorToDelete.lastName }}?
        </h3>
      </div>
      <div class="mt-2" align="right">
        <b-button
          variant="primary"
          @click="$bvModal.hide('delete-contractor-modal')"
          >Cancel</b-button
        >
        <b-button
          class="ml-1"
          variant="outline-danger"
          @click="DeleteContractor()"
          >Delete</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BModal,
  BSidebar,
  BCard,
  BCardBody,
  BCardTitle,
  BDropdownItem,
  BCollapse,
  BBadge,
  BFormGroup,
  BButton,
  VBToggle,
  BForm,
  BRow,
  BCol,
  BDropdownGroup,
  BAvatar,
  BFormCheckbox,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// import Mixins
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import vSelect from 'vue-select'

// import validation for editors
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

// import Services

import { contractor } from '@/services/contractor.service.js'

// Import Table
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

// Import Icons
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'
import CreateContractorWizard from './CreateContractorWizard.vue'

// Declare component
export default {
  name: 'ContractorManager',
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    BButton,
    VBToggle,
    BAvatar,
    BModal,
    BBadge,
    BPagination,
    BForm,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BSidebar,
    BCard,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BDropdownGroup,
    BFormCheckbox,
    FeatherIcon,
    BFormInvalidFeedback,
    vSelect,
    CreateContractorWizard
  },
  directives: {
    FeatherIcon,
    'b-toggle': VBToggle,
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      contractorToDelete: {
        firstName: '',
        lastName: '',
        azureID: '',
        status: 'Pending',
        siteIds: [
          {
            azureID: '',
            _id: '',
            cameraIds: [],
            rdpIds: [],
            quickLinkIds: []
          }
        ]
      },
      searchTerm: '',
      pageLength: 5,
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          width: '10%'
        },
        {
          label: 'Email',
          field: 'email',
          width: '10%'
        },
        {
          label: 'Azure ID',
          field: 'azureID',
          width: '10%'
        },
        {
          label: 'Cameras',
          field: 'cameras',
          width: '10%'
        },
        {
          label: 'RDPs',
          field: 'rdps',
          width: '10%'
        },
        {
          label: 'QuickLinks',
          field: 'qlinks',
          width: '10%'
        },
        {
          label: 'status',
          field: 'status',
          width: '10%'
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '10%'
        }
      ],
      cams: [],
      rdps: [],
      qlinks: []
    }
  },
  methods: {
    handleRouteToEditor(id) {
      this.$router.push('/contractoreditor/' + id)
    },
    ////////////////////////////////////////////////////// Sidebar Controls ///////////////////////////////////////////////////////
    showCamerasInSidebar(cameras) {
      this.cams = cameras
      this.$root.$emit('bv::toggle::collapse', 'sidebar-camera')
    },
    showRDPsInSidebar(rdps) {
      this.rdps = rdps
      this.$root.$emit('bv::toggle::collapse', 'sidebar-rdps')
    },
    showQuickLinksInSidebar(qlinks) {
      this.qlinks = qlinks
      this.$root.$emit('bv::toggle::collapse', 'sidebar-qlinks')
    },
    ////////////////////////////////////////////////////// Contractor Deletion Controls ///////////////////////////////////////////
    DeleteContractor() {
      contractor
        .deleteContractor(this.contractorToDelete)
        .then((res) => {
          // set contractor data to edited
          this.$bvModal.hide('delete-contractor-modal')
          window.Bus.instance.$emit('pageSuccess', {
            header: 'Success',
            detail: 'Contractor Successfully Deleted'
          })
          this.getAllContractors()
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'Error',
            detail: 'Could not Delete Contractor'
          })
        })
    },
    showDeleteModal(contractor) {
      this.contractorToDelete = contractor
      this.$bvModal.show('delete-contractor-modal')
    },
    clearDeleteModal() {
      this.contractorToDelete = {
        name: '',
        url: '',
        shortname: '',
        group: '',
        status: '',
        class: 'primary',
        _id: ''
      }
    },
    ////////////////////////////////////////////////////// Contractor Creation Controls ///////////////////////////////////////////

    showCreationWizard() {
      this.$root.$emit('bv::toggle::collapse', 'contractor-creator')
    },

    resetCreator() {
      this.$root.$emit('bv::toggle::collapse', 'contractor-creator')
    },

    ///////////////////////////////////////////////////////// Get Data ////////////////////////////////////////////////////////////
    getAllContractors() {
      contractor
        .getContractors()
        .then((res) => {
          this.rows = res.data
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'Error',
            detail: error.message
          })
        })
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Enabled: 'light-success',
        Disabled: 'light-warning'
        /* eslint-enable key-spacing */
      }

      return (status) => statusColor[status]
    }
  },
  created: function () {
    window.Bus.instance.$on('updateContractorList', () => {
      // refresh Site List
      this.getAllContractors()
      // Minimize Site Creation Wizard
      this.resetCreator()
    })
  },
  async mounted() {
    // Get Contractor Info
    this.getAllContractors()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
.table-icon {
  color: $primary;
  cursor: pointer;
}
.link:hover {
  color: $primary;
  cursor: pointer;
  text-decoration: underline;
}
.trash-icon {
  color: $danger !important;
}
.align-text-center {
  text-align: center;
}
.placeholder {
  padding: 1rem;
  max-height: 100%;
  max-width: 100%;
}
/* The Close Button */
.close {
  margin-right: -3px !important;
  margin-top: -3px !important;
}
.bg-lighten-1 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
</style>
