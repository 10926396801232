var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"simpleRules"},[_c('form-wizard',{ref:"form",staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Review","back-button-text":"Previous"},on:{"on-complete":_vm.verifyModal}},[_c('tab-content',{attrs:{"title":"Contractor Details","before-change":_vm.validateDetails}},[_c('validation-observer',{ref:"detailsCreate",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"contractor-name"}},[_c('validation-provider',{attrs:{"rules":"required|noSpecial","name":"ContractorName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contractor-name","placeholder":"Contractor Name","label":"title","state":errors.length > 0 ? false : null},model:{value:(_vm.newContractor.firstName),callback:function ($$v) {_vm.$set(_vm.newContractor, "firstName", $$v)},expression:"newContractor.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"modal":"4"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"contractor-last-name"}},[_c('validation-provider',{attrs:{"rules":"required|noSpecial","name":"LastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contractor-last-name","placeholder":"Contractor Last Name","min":"4","state":errors.length > 0 ? false : null},model:{value:(_vm.newContractor.lastName),callback:function ($$v) {_vm.$set(_vm.newContractor, "lastName", $$v)},expression:"newContractor.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"AzureID","label-for":"contractor-azureid"}},[_c('validation-provider',{attrs:{"rules":"required|validAzureID","name":"AzureID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contractor-azureid","placeholder":"AzureID","state":errors.length > 0 ? false : null},model:{value:(_vm.newContractor.azureID),callback:function ($$v) {_vm.$set(_vm.newContractor, "azureID", $$v)},expression:"newContractor.azureID"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"contractor-email"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contractor-email","placeholder":"Contractor Email","state":errors.length > 0 ? false : null},model:{value:(_vm.newContractor.email),callback:function ($$v) {_vm.$set(_vm.newContractor, "email", $$v)},expression:"newContractor.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Resource Access"}},[_c('b-row',[_c('b-col',[_c('b-card',[_c('div',{staticClass:"d-flex justify-content-right flex-wrap mr-1 ml-1"},[_c('div',[_c('div',{staticClass:"\n                        custom-search\n                        d-flex\n                        justify-content-right\n                        mt-1\n                        mb-1\n                      "},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])])]),_c('div',[_c('vue-good-table',{ref:"perm-table",attrs:{"columns":_vm.columns,"rows":_vm.rows,"group-options":{
                      enabled: true,
                      collapsable: true
                    },"select-options":{
                      enabled: true,
                      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                      disableSelectInfo: true, // disable the select info panel on top
                      selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    },"search-options":{
                      searchFn: _vm.customSearch,
                      enabled: true,
                      externalQuery: _vm.searchTerm
                    }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',[_vm._v("Action 1")])])])],1)])],1),_c('b-col',{attrs:{"md":"6"}},_vm._l((_vm.permission),function(perm){return _c('b-card',{key:perm.siteID,staticClass:"mt-2 ml-1 mr-1"},[_c('b-card-title',[_vm._v(_vm._s(perm.siteName))]),(perm.cams.length > 0)?_c('div',[_c('h5',[_vm._v("Cameras")]),_vm._l((perm.cams),function(cam){return _c('p',{key:cam.id},[_vm._v(" "+_vm._s(cam.name)+" ")])})],2):_vm._e(),(perm.rdps.length > 0)?_c('div',[_c('h5',[_vm._v("Remote Desktops")]),_vm._l((perm.rdps),function(rdp){return _c('p',{key:rdp.id},[_vm._v(" "+_vm._s(rdp.name)+" ")])})],2):_vm._e(),(perm.qlinks.length > 0)?_c('div',[_c('h5',[_vm._v("QuickLinks")]),_vm._l((perm.qlinks),function(ql){return _c('p',{key:ql.id},[_vm._v(" "+_vm._s(ql.name)+" ")])})],2):_vm._e()],1)}),1)],1)],1)],1)],1)],1),_c('b-modal',{ref:"verify-modal",attrs:{"title":"Review","centered":"","cancel-title":"Close","ok-title":"Publish","cancel-variant":"outline-secondary","bg-variant":"transparent"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
                    var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.saveContractor('Disabled')}}},[_vm._v(" Save Without Publishing ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveContractor('Enabled')}}},[_vm._v(" Publish ")])]}}])},[_c('b-row',[_c('b-col',{attrs:{"md":"12","xl":"12"}},[_c('b-card',[_c('h3',{staticClass:"font-weight-normal text-center"},[_vm._v("Contractor Name")]),_c('h4',{staticClass:"font-weight-normal text-center"},[_vm._v(" "+_vm._s(_vm.newContractor.firstName)+" "+_vm._s(_vm.newContractor.lastName)+" ")]),_c('hr'),_c('h3',{staticClass:"font-weight-normal text-center"},[_vm._v("Email")]),_c('h4',{staticClass:"font-weight-normal text-center"},[_vm._v(" "+_vm._s(_vm.newContractor.email)+" ")]),_c('hr'),_c('h3',{staticClass:"font-weight-normal text-center"},[_vm._v("Azure Id")]),_c('h4',{staticClass:"font-weight-normal text-center"},[_vm._v(" "+_vm._s(_vm.newContractor.azureID)+" ")]),_c('hr'),_c('h3',{staticClass:"font-weight-normal text-center"},[_vm._v("Resource Permissions")]),_vm._l((_vm.permission),function(perm){return _c('div',{key:perm.siteID,staticClass:"mt-2 ml-1 mr-1"},[_c('hr'),_c('h4',[_vm._v(_vm._s(perm.siteName))]),(perm.cams.length > 0)?_c('div',[_c('h5',[_vm._v("Cameras")]),_vm._l((perm.cams),function(cam){return _c('p',{key:cam.id},[_vm._v(" "+_vm._s(cam.name)+" ")])})],2):_vm._e(),(perm.rdps.length > 0)?_c('div',[_c('h5',[_vm._v("Remote Desktops")]),_vm._l((perm.rdps),function(rdp){return _c('p',{key:rdp.id},[_vm._v(" "+_vm._s(rdp.name)+" ")])})],2):_vm._e(),(perm.qlinks.length > 0)?_c('div',[_c('h5',[_vm._v("QuickLinks")]),_vm._l((perm.qlinks),function(ql){return _c('p',{key:ql.id},[_vm._v(" "+_vm._s(ql.name)+" ")])})],2):_vm._e()])}),_c('hr')],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }