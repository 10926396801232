<template>
  <b-row>
    <b-col md="12">
      <validation-observer ref="simpleRules">
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Review"
          back-button-text="Previous"
          class="mb-3"
          @on-complete="verifyModal"
          ref="form"
        >
          <!-- Contractor Details -->
          <tab-content
            title="Contractor Details"
            :before-change="validateDetails"
          >
            <validation-observer ref="detailsCreate" tag="form">
              <b-row>
                <b-col md="4">
                  <b-form-group label="First Name" label-for="contractor-name">
                    <validation-provider
                      rules="required|noSpecial"
                      name="ContractorName"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="newContractor.firstName"
                        id="contractor-name"
                        placeholder="Contractor Name"
                        label="title"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col modal="4">
                  <b-form-group
                    label="Last Name"
                    label-for="contractor-last-name"
                  >
                    <validation-provider
                      rules="required|noSpecial"
                      name="LastName"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="newContractor.lastName"
                        id="contractor-last-name"
                        placeholder="Contractor Last Name"
                        min="4"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="AzureID" label-for="contractor-azureid">
                    <validation-provider
                      rules="required|validAzureID"
                      name="AzureID"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="newContractor.azureID"
                        id="contractor-azureid"
                        placeholder="AzureID"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Email" label-for="contractor-email">
                    <validation-provider
                      rules="required|email"
                      name="Email"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="newContractor.email"
                        id="contractor-email"
                        placeholder="Contractor Email"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <!-- Resource Access Access -->
          <tab-content title="Resource Access">
            <b-row>
              <!-- selection Table -->
              <b-col>
                <b-card>
                  <div class="d-flex justify-content-right flex-wrap mr-1 ml-1">
                    <div>
                      <div
                        class="
                          custom-search
                          d-flex
                          justify-content-right
                          mt-1
                          mb-1
                        "
                      >
                        <div class="d-flex align-items-center">
                          <span class="mr-1">Search</span>
                          <b-form-input
                            v-model="searchTerm"
                            placeholder="Search"
                            type="text"
                            class="d-inline-block"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <vue-good-table
                      ref="perm-table"
                      :columns="columns"
                      :rows="rows"
                      :group-options="{
                        enabled: true,
                        collapsable: true
                      }"
                      @on-selected-rows-change="selectionChanged"
                      :select-options="{
                        enabled: true,
                        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                      }"
                      :search-options="{
                        searchFn: customSearch,
                        enabled: true,
                        externalQuery: searchTerm
                      }"
                    >
                      <div slot="selected-row-actions">
                        <button>Action 1</button>
                      </div>
                      <template slot="table-row" slot-scope="props">
                        <!-- Column: Action -->
                        <span v-if="props.column.field === 'name'">
                          {{ props.formattedRow[props.column.field] }}
                        </span>

                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </b-card>
              </b-col>

              <!-- Selected Cards -->
              <b-col md="6"
                ><b-card
                  v-for="perm in permission"
                  v-bind:key="perm.siteID"
                  class="mt-2 ml-1 mr-1"
                >
                  <b-card-title>{{ perm.siteName }}</b-card-title>
                  <div v-if="perm.cams.length > 0">
                    <h5>Cameras</h5>
                    <p v-for="cam in perm.cams" v-bind:key="cam.id">
                      {{ cam.name }}
                    </p>
                  </div>
                  <div v-if="perm.rdps.length > 0">
                    <h5>Remote Desktops</h5>
                    <p v-for="rdp in perm.rdps" v-bind:key="rdp.id">
                      {{ rdp.name }}
                    </p>
                  </div>
                  <div v-if="perm.qlinks.length > 0">
                    <h5>QuickLinks</h5>
                    <p v-for="ql in perm.qlinks" v-bind:key="ql.id">
                      {{ ql.name }}
                    </p>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
      </validation-observer>
    </b-col>

    <!-- review modal -->
    <b-modal
      ref="verify-modal"
      title="Review"
      centered
      cancel-title="Close"
      ok-title="Publish"
      cancel-variant="outline-secondary"
      bg-variant="transparent"
    >
      <b-row>
        <b-col md="12" xl="12">
          <b-card>
            <h3 class="font-weight-normal text-center">Contractor Name</h3>
            <h4 class="font-weight-normal text-center">
              {{ newContractor.firstName }} {{ newContractor.lastName }}
            </h4>
            <hr />

            <h3 class="font-weight-normal text-center">Email</h3>
            <h4 class="font-weight-normal text-center">
              {{ newContractor.email }}
            </h4>
            <hr />
            <h3 class="font-weight-normal text-center">Azure Id</h3>
            <h4 class="font-weight-normal text-center">
              {{ newContractor.azureID }}
            </h4>
            <hr />
            <h3 class="font-weight-normal text-center">Resource Permissions</h3>

            <div
              v-for="perm in permission"
              v-bind:key="perm.siteID"
              class="mt-2 ml-1 mr-1"
            >
              <hr />
              <h4>{{ perm.siteName }}</h4>
              <div v-if="perm.cams.length > 0">
                <h5>Cameras</h5>
                <p v-for="cam in perm.cams" v-bind:key="cam.id">
                  {{ cam.name }}
                </p>
              </div>
              <div v-if="perm.rdps.length > 0">
                <h5>Remote Desktops</h5>
                <p v-for="rdp in perm.rdps" v-bind:key="rdp.id">
                  {{ rdp.name }}
                </p>
              </div>
              <div v-if="perm.qlinks.length > 0">
                <h5>QuickLinks</h5>
                <p v-for="ql in perm.qlinks" v-bind:key="ql.id">
                  {{ ql.name }}
                </p>
              </div>
            </div>

            <hr />
          </b-card>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="danger" @click="cancel()"> Cancel </b-button>
        <b-button variant="secondary" @click="saveContractor('Disabled')">
          Save Without Publishing
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button variant="primary" @click="saveContractor('Enabled')">
          Publish
        </b-button>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCard,
  BCardTitle,
  BTable,
  BCardText,
  BCol,
  BTh,
  BModal,
  BCollapse,
  VBToggle,
  BFormGroup,
  BDropdown,
  BDropdownGroup,
  BDropdownItem,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormRating,
  BFormInvalidFeedback,
  BFormCheckbox,
  BForm,
  BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'

import { contractor } from '@/services/contractor.service.js'

// Import Table
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, validAzureID, noSpecial } from '@validations'

// Import Icons
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

export default {
  name: 'CreateContractorWizard',
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    FeatherIcon,
    BRow,
    BCol,
    BTable,
    BTh,
    BModal,
    BCardText,
    BCardTitle,
    BFormInvalidFeedback,
    BCard,
    BFormGroup,
    BCollapse,
    BFormRating,
    BFormRadio,
    BFormRadioGroup,
    FormWizard,
    TabContent,
    BDropdown,
    BDropdownGroup,
    BDropdownItem,
    BFormInput,
    BFormCheckbox,
    BForm,
    vSelect,
    VueGoodTable,
    BButton,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
    FeatherIcon,
    'b-toggle': VBToggle
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      validAzureID,
      noSpecial,
      
      newContractor: {
        firstName: '',
        lastName: '',
        azureID: '',
        email: '',
        status: 'Pending',
        siteIds: [
          {
            siteID: '',
            _id: '',
            cameraIds: [],
            rdpIds: [],
            quickLinkIds: []
          }
        ]
      },
      // define the default value
      searchTerm: '',
      columns: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Type',
          field: 'type'
        },
        {
          label: 'Region',
          field: 'group'
        }
      ],
      rows: [],
      permission: [],
      pastSelected: []
    }
  },
  computed: {},
  methods: {
    ////////////////////////////////////////////////////////////////// Permission picker Controls //////////////////////////
    customSearch(row, col, cellValue, searchTerm) {
      if (
        row.group.search(searchTerm) != -1 ||
        row.name.search(searchTerm) != -1 ||
        row.siteID.search(searchTerm) != -1 ||
        row.siteName.search(searchTerm) != -1
      ) {
        return cellValue
      }
    },

    selectionChanged() {
      
      let rows = this.$refs['perm-table'].selectedRows
      
      // find differences between selection and passed selection
      let add = rows.filter((x) => !this.pastSelected.includes(x))
      if (add.length > 0) {
        // add to permissions

        //for each permission check if siteID matches
        for (let j = 0; j < add.length; j++) {
          let slotted = false
          for (let i = 0; i < this.permission.length; i++) {
            // if permission matches add it to the proper array
            if (add[j].siteID === this.permission[i].siteID) {
              if (add[j].type === 'Camera') {
                this.permission[i].cams.push({
                  id: add[j].id,
                  name: add[j].name
                })
                slotted = true
              } else if (add[j].type === 'RDP') {
                this.permission[i].rdps.push({
                  id: add[j].id,
                  name: add[j].name
                })
                slotted = true
              } else {
                this.permission[i].qlinks.push({
                  id: add[j].id,
                  name: add[j].name
                })
                slotted = true
              }
            }
          }
          // if no siteIDs match any permissions add new permission with proper resource
          if (slotted === false) {
            if (add[j].type === 'Camera') {
              slotted = true
              this.permission.push({
                siteName: add[j].siteName,
                siteID: add[j].siteID,
                groupName: add[j].group,
                id: add[j].id,
                cams: [{ id: add[j].id, name: add[j].name }],
                rdps: [],
                qlinks: []
              })
            } else if (add[j].type === 'RDP') {
              slotted = true
              this.permission.push({
                siteName: add[j].siteName,
                siteID: add[j].siteID,
                groupName: add[j].group,
                id: add[j].id,
                cams: [],
                rdps: [{ id: add[j].id, name: add[j].name }],
                qlinks: []
              })
            } else {
              slotted = true
              this.permission.push({
                siteName: add[j].siteName,
                siteID: add[j].siteID,
                groupName: add[j].group,
                id: add[j].id,
                cams: [],
                rdps: [],
                qlinks: [{ id: add[j].id, name: add[j].name }]
              })
            }
          }
        }
      } else {
        let remove = this.pastSelected.filter((x) => !rows.includes(x))
        if (remove.length > 0) {
          // remove from permissions
          for (let i = 0; i < remove.length; i++) {
            let resource = remove[i]
            // find permission with resource that needs to be removed
            for (let j = 0; j < this.permission.length; j++) {
              if (this.permission[j].siteID === resource.siteID) {
                if (resource.type === 'Camera') {
                  this.permission[j].cams = this.permission[j].cams.filter(
                    (cam) => cam.id != resource.id
                  )
                } else if (resource.type === 'RDP') {
                  this.permission[j].rdps = this.permission[j].rdps.filter(
                    (rdp) => rdp.id != resource.id
                  )
                } else {
                  this.permission[j].qlinks = this.permission[j].qlinks.filter(
                    (qlink) => qlink.id != resource.id
                  )
                }

                if (
                  this.permission[j].cams.length === 0 &&
                  this.permission[j].rdps.length === 0 &&
                  this.permission[j].qlinks.length === 0
                ) {
                  this.permission.splice(j, 1)
                }
              }
            }
          }
        }
      }
      this.pastSelected = rows
    },

    // Fetch Data For Region Groups and Return Response To Groups
    async getAllSites() {
      try {
        let data = await contractor.getFormattedSites()
        this.rows = data.data
      } catch (e) {
        window.Bus.instance.$emit('pageDanger', {
          header: 'ERROR',
          detail: 'Could not retrieve Permissions'
        })
      }
    },

    // Validate Contractor Creation and check if exists
    async validateDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.detailsCreate.validate().then((success) => {
          if (success) {
            // check if email/azureid are not already taken
            contractor
              .checkUnique({
                email: this.newContractor.email,
                azureID: this.newContractor.azureID
              })
              .then((response) => {
                let err = false
                if (response.data.email === false) {
                  this.$refs.detailsCreate.setErrors({
                    Email: ['This Email is already taken.']
                  })
                  err = true
                }
                if (response.data.azureID === false) {
                  this.$refs.detailsCreate.setErrors({
                    AzureID: ['This Azure ID is already taken.']
                  })
                  err = true
                }
                if (err === true) {
                  reject()
                } else {
                  resolve(true)
                }
              })
          } else {
            reject()
          }
        })
      })
    },

    //Show confirmation modal
    verifyModal() {
      this.$refs['verify-modal'].show()
    },

    // Validate form again before submitting
    saveContractor(status) {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // set status
          this.newContractor.status = status

          // eslint-disable-next-line
          contractor.createContractor({newContractor: this.newContractor, permission: this.permission}).then((response) => {
            if (response.status) {
              this.successAlert()
            } else {
              window.Bus.instance.$emit('pageDanger', {
                header: 'ERROR',
                detail: 'Could not create new Contractor'
              })
            }
          })
        }
      })
    },

    successAlert() {
      this.$swal({
        title: 'Success!',
        text: 'Contractor created successfully.',
        icon: 'success',
        confirmButtonText: 'Dismiss',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
      // send event to contractorManager to refresh contractor list
      this.newContractor = {
        firstName: '',
        lastName: '',
        azureID: '',
        status: 'Pending',
        siteIds: [
          {
            siteID: '',
            _id: '',
            cameraIds: [],
            rdpIds: [],
            quickLinkIds: []
          }
        ]
      }
      this.permission = []
      this.pastSelected = []
      this.$refs['perm-table'].unselectAllInternal()
      this.$refs['verify-modal'].hide()
      this.$refs.form.reset()
      this.$refs.detailsCreate.reset()

      window.Bus.instance.$emit('updateContractorList')
    }
  },
  async mounted() {
    this.getAllSites()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.bg-lighten-1 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
</style>
